
export const MUTATE_NOW = 'MUTATE_NOW'
export const MUTATE_ACCOUNT = 'MUTATE_ACCOUNT'
export const MUTATE_VENDOR = 'MUTATE_VENDOR'
export const MUTATE_USERNAME = 'MUTATE_USERNAME'
export const MUTATE_NOTIFICATIONS = 'MUTATE_NOTIFICATIONS'
export const MUTATE_COUNT_NEW_MESSAGES = 'MUTATE_COUNT_NEW_MESSAGES'
export const MUTATE_SESSIONS = 'MUTATE_SESSIONS'
export const MUTATE_SESSIONS_ITEM = 'MUTATE_SESSIONS_ITEM'
export const MUTATE_SESSION = 'MUTATE_SESSION'
export const MUTATE_MAX_FILESIZE = 'MUTATE_MAX_FILESIZE'
export const MUTATE_PERMISSIONS = 'MUTATE_PERMISSIONS'
export const MUTATE_STEP = 'MUTATE_STEP'
export const MUTATE_QUIZ = 'MUTATE_QUIZ'
export const SAVE_QUIZ_QUESTION = 'SAVE_QUIZ_QUESTION'
export const MUTATE_ESSAY = 'MUTATE_ESSAY'
export const MUTATE_LECTURE = 'MUTATE_LECTURE'
export const MUTATE_LESSON = 'MUTATE_LESSON'
export const MUTATE_TOGGLE_STEPS = 'MUTATE_TOGGLE_STEPS'
export const MUTATE_TOGGLE_VIEW = 'MUTATE_TOGGLE_VIEW'
export const MUTATE_MEETINGS = 'MUTATE_MEETINGS'
export const MUTATE_VENDOR_LIMITS = 'MUTATE_VENDOR_LIMITS'
export const MUTATE_SURVEYS = 'MUTATE_SURVEYS'
export const MUTATE_SURVEYS_ITEM = 'MUTATE_SURVEYS_ITEM'
export const MUTATE_LIBRARY = 'MUTATE_LIBRARY'
export const MUTATE_LIBRARY_NODE = 'MUTATE_LIBRARY_NODE'
export const MUTATE_EXTENDED_SEARCH = 'MUTATE_EXTENDED_SEARCH'
export const MUTATE_LIBRARY_SETTINGS = 'MUTATE_LIBRARY_SETTINGS'
export const MUTATE_LIBRARY_CATALOGS = 'MUTATE_LIBRARY_CATALOGS'
export const MUTATE_LIBRARY_CURRENT_CATALOG = 'MUTATE_LIBRARY_CURRENT_CATALOG'
export const MUTATE_PAGINATION_PAGER = 'MUTATE_PAGINATION_PAGER'
export const MUTATE_LIBRARY_FILES = 'MUTATE_LIBRARY_FILES'
export const MUTATE_COURSE_BOOKS = 'MUTATE_COURSE_BOOKS'
export const MUTATE_NEWS = 'MUTATE_NEWS'
export const MUTATE_COUNT_NEW_NEWS = 'MUTATE_COUNT_NEW_NEWS'
