export const UPDATE_NOW = 'UPDATE_NOW'
export const SET_ACCOUNT = 'SET_ACCOUNT'
export const REGISTER = 'REGISTER'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const GET_ACCOUNT = 'GET_ACCOUNT'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
export const CREATE_NOTIFICATIONS = 'CREATE_NOTIFICATIONS'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS'
export const UPDATE_COUNT_NEW_MESSAGES = 'UPDATE_COUNT_NEW_MESSAGES'
export const GET_SESSIONS = 'GET_SESSIONS'
export const CONTINUE_SESSION = 'CONTINUE_SESSION'
export const START_SESSION = 'START_SESSION'
export const UPDATE_STEP = 'UPDATE_STEP'
export const SAVE_QUIZ_QUESTION = 'SAVE_QUIZ_QUESTION'
export const TOGGLE_STEPS = 'TOGGLE_STEPS'
export const TOGGLE_VIEW = 'TOGGLE_VIEW'
export const GET_MEETINGS = 'GET_MEETINGS'
export const SAVE_SCORM = 'SAVE_SCORM'
export const GET_VENDOR_LIMITS = 'GET_VENDOR_LIMITS'
export const GET_SURVEYS = 'GET_SURVEYS'
export const CONTINUE_SURVEY = 'CONTINUE_SURVEY'
export const START_SURVEY = 'START_SURVEY'
export const GET_LIBRARY = 'GET_LIBRARY'
export const GET_EXTENDED_SEARCH = 'GET_EXTENDED_SEARCH'
export const GET_LIBRARY_SETTINGS = 'GET_LIBRARY_SETTINGS'
export const GET_LIBRARY_CATALOGS = 'GET_LIBRARY_CATALOGS'
export const GET_LIBRARY_CURRENT_CATALOG = 'GET_LIBRARY_CURRENT_CATALOG'
export const GET_LIBRARY_FILES = 'GET_LIBRARY_FILES'
export const GET_COURSE_BOOKS = 'GET_COURSE_BOOKS'
export const GET_NEWS = 'GET_NEWS'
export const GET_COUNT_NEW_NEWS = 'GET_COUNT_NEW_NEWS'
