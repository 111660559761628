import { isGuest } from '../utils/role'
import { getParamFrom } from '../utils/common'

export const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "account-login" */ '../views/TheAccountLogin.vue'),
    meta: {
      layout: 'Guest',
      middleware: ({ role, next }) => isGuest(role) ? next() : next('/')
    }
  },

  {
    path: '/login/:token',
    name: 'loginWithToken',
    component: () => import(/* webpackChunkName: "account-login" */ '../views/TheAccountLogin.vue'),
    meta: {
      layout: 'Guest',
      middleware: ({ role, next }) => isGuest(role) ? next() : next('/')
    }
  },

  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "account-register" */ '../views/TheAccountRegister.vue'),
    meta: {
      layout: 'Guest',
      middleware: ({ role, vendor, next }) => {
        if (isGuest(role)) {
          if (!vendor?.branding?.self_registration_enabled) {
            return next('/login')
          }

          return next()
        }

        return next('/')
      }
    }
  },

  {
    path: '/reset',
    name: 'reset',
    component: () => import(/* webpackChunkName: "account-reset" */ '../views/TheAccountReset.vue'),
    meta: {
      layout: 'Guest',
      middleware: ({ role, next }) => isGuest(role) ? next() : next('/')
    }
  },

  {
    path: '/sessions',
    name: 'sessions',
    component: () => import(/* webpackChunkName: "sessions" */ '../views/TheSessions.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '/sessions/:sessionID',
    name: 'session',
    component: () => import(/* webpackChunkName: "session" */ '../views/TheSession.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '/sessions/:sessionID/steps/:stepID',
    name: 'training',
    component: () => import(/* webpackChunkName: "training" */ '../views/TheTraining.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '/library',
    name: 'library',
    component: () => import(/* webpackChunkName: "library" */ '../views/TheLibrary.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '/library/documents',
    name: 'documents',
    component: () => import(/* webpackChunkName: "library" */ '../views/TheLibraryDocuments.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '/library/documents/:id',
    name: 'currentDocument',
    component: () => import(/* webpackChunkName: "library" */ '../views/TheLibraryCurrentDocument.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '/library/book/:id',
    name: 'currentBook',
    component: () => import(/* webpackChunkName: "library" */ '../views/TheLibraryBookPage.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '/messages',
    name: 'messenger',
    props: true,
    component: () => import(/* webpackChunkName: "messenger" */ '../views/TheMessenger.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '/marks',
    name: 'marks',
    component: () => import(/* webpackChunkName: "marks" */ '../views/TheMarks.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '/certificates/:id',
    name: 'certificate',
    component: () => import(/* webpackChunkName: "certificate" */ '../views/TheCertificate.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '/history',
    name: 'history',
    component: () => import(/* webpackChunkName: "history" */ '../views/TheHistory.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '/meetings',
    name: 'meetings',
    component: () => import(/* webpackChunkName: "meetings" */ '../views/TheMeetings.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '/meetings/:meetingID',
    name: 'meeting',
    component: () => import(/* webpackChunkName: "meeting" */ '../views/TheMeeting.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '/surveys',
    name: 'surveys',
    component: () => import(/* webpackChunkName: "surveys" */ '../views/TheSurveys.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '/surveys/:surveyID',
    name: 'survey',
    component: () => import(/* webpackChunkName: "survey" */ '../views/TheSurvey.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '/users/:userID',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ '../views/TheUser.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '/users/:userID/edit',
    name: 'user-edit',
    component: () => import(/* webpackChunkName: "user-edit" */ '../views/TheUserEdit.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '*',
    name: 'error',
    component: () => import(/* webpackChunkName: "error-page" */ '../views/TheErrorPage.vue'),
    meta: {
      layout: 'Empty'
    }
  },

  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "error-page" */ '../views/ThePrivacyPolicy.vue'),
    meta: {
      layout: () => {
        const account = window.localStorage.getItem('account')
        return account === '0' ? 'Empty' : 'Main'
      }
    }
  },

  {
    path: '/users/:userID/required-fields',
    name: 'user-required-fields',
    component: () => import(/* webpackChunkName: "user-edit" */ '../views/TheUserRequiredFields.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/TheNews.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },

  {
    path: '/news/:singleNewsID',
    name: 'singleNews',
    component: () => import(/* webpackChunkName: "news" */ '../views/TheSingleNews.vue'),
    meta: {
      layout: 'Main',
      middleware: ({ role, next }) => !isGuest(role) ? next() : next(`/login${getParamFrom()}`)
    }
  },
]
