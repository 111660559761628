import * as types from './types'

const mutateNow = (state, now = Date.now()) => {
  state.now = now
}

const mutateAccount = (state, account) => {
  state.account = account
}

const mutateVendor = (state, vendor) => {
  state.vendor = vendor
}

const mutateUsername = (state, username) => {
  state.username = username
}

const mutateNotifications = (state, notifications = []) => {
  state.notifications = notifications
}

const mutateCountNewMessages = (state, val = 0) => {
  state.countNewMessages = val
}

const mutateSessions = (state, sessions) => {
  state.sessions = sessions
}

const mutateSessionsItem = (state, session = {}) => {
  const idx = state.sessions.findIndex(s => s.order_id === session.order_id)
  if (idx !== -1) {
    state.sessions.splice(idx, 1, session)
  }
}

const mutateSession = (state, session = {}) => {
  state.session = session
}

const mutateStep = (state, step = {}) => {
  state.step = step
}

const mutatePermissions = (state, permissions = {}) => {
  state.permissions = permissions
}

const mutateQuiz = (state, quiz) => {
  state.quiz = quiz
}

const saveQuizQuestion = (state, { question = {}, userAnswer = [] }) => {
  const cloneQuiz = Object.assign({}, state.quiz)

  if (question && question.quiz_id === cloneQuiz.id) {
    const id = question.question_id
    const q = (cloneQuiz.questions || []).find(q => q.question_id === id)
    Object.assign(q, question)

    cloneQuiz.user_answers = Object.assign({}, cloneQuiz.user_answers, {
      [id]: userAnswer
    })

    state.quiz = cloneQuiz
  }
}

const mutateEssay = (state, essay) => {
  state.essay = essay
}

const mutateMaxFilesize = (state, maxFileSize) => {
  state.maxFilesize = maxFileSize
}

const mutateLecture = (state, lecture) => {
  state.lecture = lecture
}

const mutateLesson = (state, lesson) => {
  state.lesson = lesson
}

const mutateToggleSteps = (state, payload) => {
  state.isOpenSteps = payload
}

const mutateToggleView = (state, payload) => {
  state.isFullView = payload
}

const mutateMeetings = (state, meetings) => {
  state.meetings = meetings
}

const mutateVendorLimits = (state, vendorLimits) => {
  state.vendorLimits = vendorLimits
}

const mutateSurveys = (state, surveys) => {
  state.surveys = surveys
}

const mutateSurveysItem = (state, survey = {}) => {
  const idx = state.surveys.findIndex(s => s.id === survey.order_id)
  if (idx !== -1) {
    state.sessions.splice(idx, 1, survey)
  }
}

const mutateLibrary = (state, account) => {
  state.library = account.nodes
}

const mutateExtendedSearch = (state, search) => {
  state.extendedSearch = search.nodes
}

const mutateLibrarySettings = (state, settings) => {
  state.librarySettings = settings.settings
}

const mutateLibraryCatalogs = (state, catalogs) => {
  state.libraryCatalogs = catalogs.catalogs
}

const mutateLibraryCurrentCatalog = (state, catalog) => {
  state.libraryCurrentCatalog = catalog.nodes
}

const mutatePaginationPager = (state, search) => {
  state.paginationPager = search.pager
}

const mutateLibraryFiles = (state, search) => {
  state.libraryFiles = search.files
}

const mutateCourseBooks = (state, books) => {
  state.courseBooks = books.nodes
}

const mutateNews = (state, news) => {
  state.news = news
}

const mutateCountNewNews = (state, val = 0) => {
  state.countNewNews = val
}

export default {
  [types.MUTATE_NOW]: mutateNow,
  [types.MUTATE_ACCOUNT]: mutateAccount,
  [types.MUTATE_VENDOR]: mutateVendor,
  [types.MUTATE_USERNAME]: mutateUsername,
  [types.MUTATE_NOTIFICATIONS]: mutateNotifications,
  [types.MUTATE_COUNT_NEW_MESSAGES]: mutateCountNewMessages,
  [types.MUTATE_SESSIONS]: mutateSessions,
  [types.MUTATE_SESSIONS_ITEM]: mutateSessionsItem,
  [types.MUTATE_SESSION]: mutateSession,
  [types.MUTATE_STEP]: mutateStep,
  [types.MUTATE_PERMISSIONS]: mutatePermissions,
  [types.MUTATE_QUIZ]: mutateQuiz,
  [types.SAVE_QUIZ_QUESTION]: saveQuizQuestion,
  [types.MUTATE_ESSAY]: mutateEssay,
  [types.MUTATE_MAX_FILESIZE]: mutateMaxFilesize,
  [types.MUTATE_LECTURE]: mutateLecture,
  [types.MUTATE_LESSON]: mutateLesson,
  [types.MUTATE_TOGGLE_STEPS]: mutateToggleSteps,
  [types.MUTATE_TOGGLE_VIEW]: mutateToggleView,
  [types.MUTATE_MEETINGS]: mutateMeetings,
  [types.MUTATE_VENDOR_LIMITS]: mutateVendorLimits,
  [types.MUTATE_SURVEYS]: mutateSurveys,
  [types.MUTATE_SURVEYS_ITEM]: mutateSurveysItem,
  [types.MUTATE_LIBRARY]: mutateLibrary,
  [types.MUTATE_EXTENDED_SEARCH]: mutateExtendedSearch,
  [types.MUTATE_LIBRARY_SETTINGS]: mutateLibrarySettings,
  [types.MUTATE_LIBRARY_CATALOGS]: mutateLibraryCatalogs,
  [types.MUTATE_LIBRARY_CURRENT_CATALOG]: mutateLibraryCurrentCatalog,
  [types.MUTATE_LIBRARY_FILES]: mutateLibraryFiles,
  [types.MUTATE_COURSE_BOOKS]: mutateCourseBooks,
  [types.MUTATE_PAGINATION_PAGER]: mutatePaginationPager,
  [types.MUTATE_NEWS]: mutateNews,
  [types.MUTATE_COUNT_NEW_NEWS]: mutateCountNewNews
}
